import React, { useState, useEffect } from "react";
import NotificationsService from "../services/notifications.service";
import { INotification } from "../types/notification.type";
import { useMediaQuery } from "react-responsive";
import { downloadReport } from "../services/report.service";

const Notifications = () => {
  const userId = localStorage.getItem("user");
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  const rows = isMobile ? 5 : 10;
  const [isOpenNotif, setIsOpenNotif] = useState(false);

  const [notif, setNotif] = useState(null);
  const [count, setCount] = useState(0);
  const [topbarNotificationNumber, setTopbarNotificationNumber] = useState(0);

  useEffect(() => {
    getLastNotif();
    getUserNotificationProperties();
  }, []);

  useEffect(() => {
    if (isOpenNotif && userId) {
      getUserNotificationProperties();
    }
  }, [isOpenNotif]);

  const getUserNotificationProperties = () => {
    NotificationsService.getUserNotificationProperties(userId).then(
      (response) => {
        if (response.data) {
          getCountNotif(response.data.active, response.data.milliseconds);
        }
      }
    );
  };

  const getCountNotif = (isActive, notificationsMilliseconds) => {
    let interval = null;

    if (isActive) {
      interval = setInterval(() => {
        setCount((count) => count + 1);
        if (userId) {
          NotificationsService.getCountUncheckedNotifications(userId).then(
            (response) => {
              setTopbarNotificationNumber(response.data);
              getLastNotif();
            }
          );
        }
      }, notificationsMilliseconds);
    } else if (!isActive && count !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  };

  const handleIsOpenNotif = () => {
    setIsOpenNotif(!isOpenNotif);
  };

  const getLastNotif = () => {
    NotificationsService.getLastNotificationsByUserId(userId, rows).then(
      (response) => {
        if (response.data) {
          if (response.data.length > 0) {
            setNotif(response.data);
          }
        }
      }
    );
  };

  const getSumaryByNotificationType = (type: string): string => {
    if (type == "REPORT") {
      return "Reporte";
    } else if (type == "SYNC") {
      return "Sincronización";
    }
    return "Otro";
  };

  const getIconByNotificationType = (type: string): string => {
    if (type == "REPORT") {
      return "pi pi-book";
    } else if (type == "SYNC") {
      return "pi pi-replay";
    }
    return "pi pi-exclamation-circle";
  };

  const onReportNotificationClick = (reportId, reportName) => {
    downloadReport(reportId, reportName);
  };

  const onNotificationClickByType = (notification: INotification): void => {
    clearNotif(notification._id);
    if (notification.type == "REPORT") {
      onReportNotificationClick(notification.reportId, notification.reportName);
    }
    NotificationsService.setCheckNotification(notification._id);
  };

  const clearNotif = (id) => {
    NotificationsService.setCheckNotification(id).then((response) => {
      if (response) {
        getLastNotif();
      }
    });
  };

  return (
    <li className="notifications-item">
      <button
        type="button"
        className="p-link"
        onClick={() => handleIsOpenNotif()}
      >
        <i className="pi pi-bell"></i>
        {topbarNotificationNumber > 0 ? (
          <span className="topbar-badge indicator">
            {topbarNotificationNumber}
          </span>
        ) : null}
      </button>

      {isOpenNotif && (
        <ul className="lista fade-in-up">
          {notif ? (
            notif.map((n) => (
              <NotificationListComponent
                key={n._id}
                data={n}
                clearNotif={clearNotif}
                getIconByNotificationType={getIconByNotificationType}
                getSumaryByNotificationType={getSumaryByNotificationType}
                onNotificationClickByType={onNotificationClickByType}
              />
            ))
          ) : (
            <div style={{ textAlign: "center", padding: "0.5rem" }}>
              No hay notificaciones
            </div>
          )}
        </ul>
      )}
    </li>
  );
};
export default Notifications;

const NotificationListComponent = ({
  data,
  clearNotif,
  getIconByNotificationType,
  getSumaryByNotificationType,
  onNotificationClickByType,
}) => {
  return (
    <li className={data.check ? "notif visto" : "notif"}>
      <button
        type="button"
        className="p-link"
        tabIndex={0}
        onClick={(event) => clearNotif(data._id)}
      >
        <i className={getIconByNotificationType(data.type)}></i>
        <div className="notification-item">
          <span className="notification-summary">
            {getSumaryByNotificationType(data.type) + " - "}
          </span>
          <span className="notification-detail">{data.message}</span>
        </div>
      </button>
      <button
        className="dwnload"
        onClick={() => onNotificationClickByType(data)}
      >
        <i className="pi pi-cloud-download"></i>
      </button>
    </li>
  );
};
