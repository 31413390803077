import http from "../common/components/http-common";

class NotificationsService {
  getNotificationsByUserId(userId: string) {
    return http.get<any>(`/notifications/userId/${userId}`);
  }

  getLastNotificationsByUserId(userId: string, top: number) {
    return http.get<any>(
      `/notifications/getLastNotificationsByUser/${userId}/${top}`
    );
  }

  getCountUncheckedNotifications(userId: string) {
    return http.get<any>(
      `/notifications/countUncheckedNotifications/userId/${userId}`
    );
  }

  getUserNotificationProperties(userId: string) {
    return http.get<any>(`/notifications/userNotificationProperties/${userId}`);
  }

  setCheckNotification(notificationId: string) {
    return http.put<any>(`/notifications/check/${notificationId}`);
  }
}
export default new NotificationsService();
