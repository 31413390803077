import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthDataService from "./common/services/auth.service";
import ConfigEnviroment from "./config/ConfigEnviroment";
import { ObtenerPermisosUser } from "./common/components/ObtenerPermisosUser";
import { RootMenu } from "./components/RootMenu";

export default function Root(props) {
  useEffect(() => {
    if (props.name === "@froneus/evi-nav-user-menu") {
      AuthDataService.validate().catch((e) => {
        if (e.response.status === 401) {
          window.location.href = ConfigEnviroment.getLoginUrl();
        }
      });
    }
    ObtenerPermisosUser();
  }, [props]);

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/inicio" element={<></>} />
        <Route path="/listado" element={<></>} />
      </Routes>
      <RootMenu />
    </BrowserRouter>
  );
}
