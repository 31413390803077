import React from "react";

export const BtnEquis = ({ isOpen }) => {
  return (
    <div className="btn-equis">
      <div className={`container ${isOpen ? "equis" : ""}`}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
};
