import React, { useRef } from "react";
import Lottie from "react-lottie";

export const LottieIcon = ({
  icon,
  height = 100,
  width = 100,
  loop = false,
}) => {
  const lottieRef = useRef(null);

  const defaultOptions = {
    loop,
    autoplay: false,
    animationData: icon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleMouseEnter = () => {
    if (lottieRef.current) {
      lottieRef.current.anim.goToAndPlay(0, true);
    }
  };

  return (
    <div onMouseEnter={handleMouseEnter} style={{ cursor: "pointer" }}>
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        ref={lottieRef}
      />
    </div>
  );
};
