import http from "../common/components/http-common";

export const getByReportById = (reportId: string) => {
  return http.get<any>(`/v1/report/${reportId}`);
};

export const downloadReport = (reportId: string, reportName: string) => {
  return http
    .request({
      url: "/v1/report/" + reportId,
      method: "get",
      responseType: "blob", //important
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", reportName); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};
