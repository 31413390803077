import { useState } from "react";
import Notifications from "./Notifications";
import AppMenu from "./AppMenu";
import { BreadCrumbComp } from "./BreadCrumbComp";
import { BtnEquis } from "./BtnEquis";

const AppTopbar = ({ menuItems }) => {
  const usuario = localStorage.getItem("userName");
  const [logoutVisible, setLogoutVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(null);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const clearLog = () => {
    return (event: React.MouseEvent) => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
    };
  };

  return (
    <div className="layout-topbar">
      <div className="topbar-left" style={{ overflowX: "auto" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            type="button"
            className="menu-button p-link"
            onClick={() => handleIsOpen()}
          >
            <BtnEquis isOpen={isOpen} />
          </button>
          <div
            className="layout-breadcrumb viewname"
            style={{ textTransform: "uppercase" }}
          >
            <BreadCrumbComp setActive={setActive} />
          </div>
        </div>
      </div>
      <AppMenu
        menuItems={menuItems}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        active={active}
        setActive={setActive}
      />
      <div className="topbar-right">
        <ul className="topbar-menu">
          <Notifications />
          <li className="profile-item">
            <button
              type="button"
              className="p-link ml-2 btn-user"
              onClick={() => setLogoutVisible(!logoutVisible)}
            >
              <i className="pi pi-user"></i>
              <span className="profile-name ml-1 desk-user">{usuario} </span>
            </button>

            {logoutVisible && (
              <ul
                className="profile-menu fade-in-up"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <li>
                  <span className="mob-user">{usuario}</span>
                  <button type="button" className="p-link" onClick={clearLog()}>
                    <i className="pi pi-power-off"></i>
                    <span>Cerrar Sesión</span>
                  </button>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppTopbar;
