import React, { useEffect, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { useNavigate } from "react-router-dom";

export const BreadCrumbComp = ({ setActive }) => {
  const [currentUrl, setCurrentUrl] = useState("");
  const [items, setItems] = useState(null);
  const [homeBreadCrumb, setHomeBreadCrumb] = useState(null);

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, [window.location.href]);

  const navigate = useNavigate();

  const _url = [
    "/inicio",
    "/user",
    "/monitoring",
    "/listado",
    "/voicebot",
    "/reporte",
    "/configuration",
    "/config-site",
    "/config-calls",
    "/config-voicebots",
    "/config-audits",
    "/audit",
    "/user-profile",
    "/agente-listado",
    "/agente-detalle",
  ];

  useEffect(() => {
    window.addEventListener("single-spa:routing-event", onRouting);
    return () => {
      window.removeEventListener("single-spa:routing-event", onRouting);
    };
  }, []);

  const onRouting = (customEvent: any): void => {
    let urlUniversal = customEvent.currentTarget.location.origin;
    let url = customEvent.currentTarget.location.pathname;
    let itemsVista = [];
    let label = {};

    const normalizedUrl = url.split("?")[0].split("#")[0];

    if (normalizedUrl === "/detalle") {
      itemsVista = [
        {
          label: sessionStorage.getItem("surveyName"),
          url: urlUniversal + "/listado",
        },
        {
          label: sessionStorage.getItem("campanaName"),
          url: urlUniversal + "/detalle",
        },
      ];
      setActive("Campañas");
    }

    if (normalizedUrl === "/agente-detalle") {
      itemsVista = [
        {
          label: sessionStorage.getItem("surveyName"),
          url: urlUniversal + "/agente-listado",
        },
        {
          label: sessionStorage.getItem("campanaName"),
          url: urlUniversal + "/agente-detalle",
        },
      ];
      setActive("Agente Inteligente");
    }

    const _type = sessionStorage.getItem("campanaType");
    const listUrl = _type === "saliente" ? "/listado" : "/agente-listado";
    const detailUrl = _type === "saliente" ? "/detalle" : "/agente-detalle";
    const _active = _type === "saliente" ? "Campañas" : "Agente Inteligente";

    if (normalizedUrl === "/llamado") {
      itemsVista = [
        {
          label: sessionStorage.getItem("surveyName"),
          url: urlUniversal + listUrl,
        },
        {
          label: sessionStorage.getItem("campanaName"),
          url: urlUniversal + detailUrl,
        },
        {
          label: sessionStorage.getItem("surveyReponseNumber"),
          url: urlUniversal + "/llamado",
        },
      ];
      setActive(_active);
    }

    if (normalizedUrl === "/listado") {
      label = { label: "LISTADO DE CAMPAÑAS", to: urlUniversal + "/listado" };
      itemsVista.push(label);
      setActive("Campañas");
    }

    if (normalizedUrl === "/agente-listado") {
      label = {
        label: "LISTADO DE AGENTES",
        to: urlUniversal + "/agente-listado",
      };
      itemsVista.push(label);
      setActive("Agente Inteligente");
    }

    if (normalizedUrl === "/user-profile") {
      label = {
        label: "PERFIL DE USUARIO",
        to: urlUniversal + "/user-profile",
      };
      itemsVista.push(label);
      setActive("Perfil de Usuario");
    }

    /** */

    const configRoutes = {
      "/config-site": "CONFIGURACIÓN SITIO",
      "/config-calls": "CONFIGURACIÓN LLAMADOS",
      "/config-voicebots": "CONFIGURACIÓN VOICEBOTS",
      "/config-audits": "CONFIGURACIÓN SPEECH ANALYTICS",
    };

    if (normalizedUrl.startsWith("/config-")) {
      if (configRoutes[normalizedUrl]) {
        label = {
          label: configRoutes[normalizedUrl],
          to: urlUniversal + normalizedUrl,
        };
        itemsVista.push(label);
        setActive("Configuración");
      }
    }

    if (normalizedUrl === "/configuration") {
      label = { label: "CONFIGURACION", to: urlUniversal + "/conmfiguration" };
      itemsVista.push(label);
      setActive("Configuración");
    }

    /** */

    if (normalizedUrl === "/monitoring") {
      label = { label: "MONITOREO", to: urlUniversal + "/monitoring" };
      itemsVista.push(label);
      setActive("Monitoreo");
    }

    if (normalizedUrl === "/voicebot") {
      label = { label: "VOICEBOTS", to: urlUniversal + "/voicebot" };
      itemsVista.push(label);
      setActive("Voicebots");
    }

    if (normalizedUrl === "/reporte") {
      label = { label: "ANALYTICS", to: urlUniversal + "/reporte" };
      itemsVista.push(label);
      setActive("Analytics");
    }

    if (normalizedUrl === "/audit") {
      label = { label: "SPEECH ANALYTICS", to: urlUniversal + "/audit" };
      itemsVista.push(label);
      setActive("Speech Analytics");
    }

    if (_url.includes(normalizedUrl)) {
      setHomeBreadCrumb(null);
      setItems(null);
    }

    if (itemsVista.length > 0) {
      setHomeBreadCrumb({ icon: "pi pi-home", url: urlUniversal + "/inicio" });
      setItems(itemsVista);
    }

    if (normalizedUrl === "/inicio") {
      setActive("Inicio");
    }
  };

  const inConfig = () => {
    const normalizedUrl = currentUrl.split("?")[0].split("#")[0];
    if (normalizedUrl.includes("config-site")) {
      navigate("/configuration");
    }
    if (normalizedUrl.includes("config-calls")) {
      navigate("/configuration");
    }
    if (normalizedUrl.includes("config-voicebots")) {
      navigate("/configuration");
    }
    if (normalizedUrl.includes("config-audits")) {
      navigate("/configuration");
    }
  };

  return (
    <>
      <button
        style={{ border: "0", background: "transparent" }}
        onClick={() => inConfig()}
      >
        {items ? (
          <BreadCrumb
            className="breadCrumbNav layout-breadcrumb viewname"
            model={items}
            home={homeBreadCrumb}
          />
        ) : (
          <div className="breadCrumbMobile">FRONEUS</div>
        )}
      </button>
    </>
  );
};
