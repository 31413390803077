export const ShowView = (viewFunction, viewName) => {
  const permisos = JSON.parse(localStorage.getItem("permisos"));

  let resp = false;

  permisos &&
    permisos.forEach((p) => {
      if (p.view_function === viewFunction && p.view_name === viewName)
        resp = true;
    });

  return resp;
};
