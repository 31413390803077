import { Link } from "react-router-dom";
import { LottieIcon } from "./LottieIcon";

const AppMenu = ({ menuItems, isOpen, handleIsOpen, active, setActive }) => {
  const HomeItem = () => {
    const logo = require("../../logo/logo-froneus-icon.png");
    return (
      <button className="inicio" onClick={() => handleClick("Inicio")}>
        <Link to="/inicio" className="logo">
          <img id="app-logo" className="logo-image" src={logo} alt="logo" />
          <span className="app-name">FRONEUS</span>
        </Link>
      </button>
    );
  };

  const handleClick = (label) => {
    setActive(label);
    handleIsOpen();
  };

  return (
    <div className={isOpen ? "menu-nav open" : "menu-nav closed"}>
      <ul className="layout-container">
        <HomeItem />
        {menuItems &&
          menuItems.map((item) => (
            <MenuItem
              key={item.label}
              label={item.label}
              icon={item.icon}
              path={item.path}
              active={active}
              handleClick={handleClick}
            />
          ))}
      </ul>
    </div>
  );
};

export default AppMenu;

const MenuItem = ({ label, icon, path, active, handleClick }) => {
  return (
    <li className="menu-item">
      <button
        className={active === label ? "btn-link active" : "btn-link"}
        onClick={() => handleClick(label)}
      >
        <Link to={path} className="link-menu">
          <LottieIcon icon={icon} height={32} width={32} />
          <span className="item-label">{label}</span>
        </Link>
      </button>
    </li>
  );
};
